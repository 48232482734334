

import Parse from 'parse';
import imageCompression from 'browser-image-compression';


Parse.initialize("HgZ11efHpJ67l9V9fjbPLnFECFBds9soS9NN8gu1", "E5KCSFDLfY3WDB5FyB6bIRIoWrRBJbfDJ6zO3gSy"); //PASTE HERE YOUR Back4App APPLICATION ID AND YOUR JavaScript KEY
Parse.serverURL = 'https://parseapi.back4app.com/';


export const getData = async (sortObj, setNumbOfPages) => { // Запрос и отправка 6 элементов из базы для отображения в заказах, остатках...
    
    {
        let tmpArray = [];
        try {
           
            let parseQuery = new Parse.Query(sortObj.nameOfCollection);
            if(sortObj.class !== 'Все работы' && sortObj.class !== 'Все материалы'){
                parseQuery.contains('class', sortObj.class);
            }

            // console.log(sortObj);
            if (sortObj.sortType == 'Сначала новее'){
                parseQuery.descending('date'); 
            } else if (sortObj.sortType == 'Сначала позднее') {
                parseQuery.ascending('date'); 
            } else if (sortObj.sortType == 'Сначала дороже') {
                parseQuery.descending('sumcost'); 
            } else if (sortObj.sortType == 'Сначала дешевле') {
                parseQuery.ascending('sumcost'); 
            }

            setNumbOfPages(parseInt(await parseQuery.count() / sortObj.limit) + 1);
            
            parseQuery.skip(sortObj.page*sortObj.limit - sortObj.limit).limit(6);
            let queryResult = await parseQuery.find();

            for (let result of queryResult) {
                let tmpObject = {};
                tmpObject.id = result.id;
                tmpObject.userID = result.get("userID");
                tmpObject.class = result.get("class");
                tmpObject.date = result.get('date');
                tmpObject.classCard = result.get("class");
                tmpObject.title = result.get("title");
                tmpObject.discription = result.get("discription");
                tmpObject.numb = result.get("numb");
                tmpObject.quant = result.get("quant");
                tmpObject.phone = result.get("phone");
                tmpObject.location = result.get("location");
                tmpObject.cost = result.get("cost"); 
                tmpObject.sumcost = result.get("sumcost");  
                tmpObject.img = result.get("img"); 
                tmpObject.imgId = result.get("imgId"); 

                tmpArray.push(tmpObject);
            };
            // console.log(tmpArray);
            return tmpArray;

        } catch (error) {
            console.log('Ошибка считывания из базы! ', error);
        };
    };
};

export const setLengthOfCollection = async (name, numb, setNumbOfPages) => { // Запрос и установка длины всей колекции в базе для подсчета страниц пагинации...
    let parseQuery = new Parse.Query(name);
    setNumbOfPages(parseInt(await parseQuery.count() / numb) + 1);
};

export async function uploadImage(nameGallery, image) { // Отправка в базу файла изображения и получение пути и идентификатора файла в базе...
    console.log(image);
    const parseFile = new Parse.File(image.name, image);
    try {
        const responseFile = await parseFile.save();
        const Gallery = Parse.Object.extend(nameGallery);
        const gallery = new Gallery();
        gallery.set('picture', responseFile);
        gallery.set('userId');
        console.log(gallery)
        await gallery.save();
        alert('Файл успешно сохранен!');
        return {url: gallery.get('picture').url(), id: gallery.id};  
        
    } catch (error) {
        console.log(
            'Ошибка сохранения файла! ', error,
        );
    }
};


export const compressImage = async (img) => { // Получение, сжатие и возврат сжатого файла для отправки в базу...
    const imageFile = img;
    const options = {
      maxSizeMB: 0.2,
      maxWidthOrHeight: 500
    }
    try {
      const compressedFile = await imageCompression(imageFile, options);
      return (compressedFile);
    } catch (error) {
      console.log('Ошибка сжатия файла! ', error);
    }      
  };


