import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    user: {
      email: '',
      userId: '',
      name: '',
      photoURL: '',
      isLogin: false,
      role: '',
      displayName: '',
      phone: '',
      ava: '',
      city: '',
      // emailVerified: false
    }
  };

  const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
      setUser: (state, action) => {         
        state.user = action.payload;        
      },
      
      exitUser: (state)=>{
        state.user = {
          email: '',
          userId: '',
          name: '',
          photoURL: '',
          isLogin: false,
          role: '',
          displayName: '',
          phone: '',
          ava: '',
          city: '',
          // emailVerified: false
        }    
    },
  }
  })
  
  export const { setUser, exitUser } = authSlice.actions
  export default authSlice.reducer