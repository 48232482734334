import React, { useEffect, useState } from 'react';
// import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
// import Card from '../components/CustomCard';
import { Container } from 'react-bootstrap';


import CustomCard from '../components/CustomCard';

// import { useSelector } from 'react-redux';

import cards from '../app/cards';
import CustomPagination from '../ui/CustomPagination';
import SortPanel from '../ui/SortPanel';
import AddNewCard from './AddNewCard';

import * as HandleData from './../app/functions'

import Parse from 'parse';
Parse.initialize("HgZ11efHpJ67l9V9fjbPLnFECFBds9soS9NN8gu1", "E5KCSFDLfY3WDB5FyB6bIRIoWrRBJbfDJ6zO3gSy");
Parse.serverURL = 'https://parseapi.back4app.com/';

const NUMBITEMS = 6;

let sortObj = {};


const RemainsPage = (props) => {

    const [isLoading, setIsLoading] = useState(true);   


    // const [items, setItems] = useState([]);
    const [numbOfPages, setNumbOfPages] = useState(parseInt(cards.cardsRemains.length / NUMBITEMS) + 1);
    const [currentSort, setCurrentSort] = useState(cards.sortItems[0].sort); // Переменная для сортировки 
    const [currentClass, setCurrentClass] = useState(cards.classesRemains[0].class); // Переменная для отбора 
    const [currentPage, setCurrentPage] = useState(1);

    const [addCard, setAddCard] = useState(false);
    const [list, setList] = useState([]); // Отображаемый список (приходит с сервера отсортированным)

    useEffect(() => {
        HandleData.setLengthOfCollection("Order", NUMBITEMS, setNumbOfPages);
    }, []);

    useEffect(() => {
        const tryRead = async () => {
            setIsLoading(true);
            sortObj.nameOfCollection = 'Remain';
            sortObj.class = currentClass;
            sortObj.start = 0;
            sortObj.page = currentPage;
            sortObj.limit = NUMBITEMS;
            sortObj.sortType = currentSort;
            try {
                setList(await HandleData.getData(sortObj, setNumbOfPages));
                setIsLoading(false);
            } catch (error) {
                console.log('UseEffect error: ', error);
            }
        };
        tryRead();

    }, [currentClass, currentSort, currentPage]);


    // const currentNumbOfPages = () => {
    //     let countPages = parseInt(cards.cardsRemains.length / NUMBITEMS);
    //     // console.log(countPages);
    //     return countPages + 1;
    // };


    return (

        <Container className='p-0'>
            {addCard ?
                <AddNewCard type={'Remain'}
                    list={cards.classesRemains.slice(1)}
                    title={'ТИП МАТЕРИАЛОВ: '}
                    setAddCard={setAddCard}>
                </AddNewCard>
                :
                <div>
                    <Row>
                        <SortPanel
                            isLogin={props.isLogin}
                            setAddCard={setAddCard}
                            leftTitle={currentClass}
                            rightTitle={currentSort}
                            leftItems={cards.classesRemains}
                            rightItems={cards.sortItems}
                            setCurrentSort={setCurrentSort} setCurrentClass={setCurrentClass}>
                        </SortPanel>
                    </Row>

                    {
                        isLoading ?
                            <Container>
                                <Row className="justify-content-md-center mt-3 mb-3">
                                    <Col md="auto">
                                        <Spinner animation="border" role="status" />
                                    </Col>
                                </Row>
                                <Row className="justify-content-md-center mt-3">
                                    <Col md="auto">
                                        ЗАГРУЗКА...
                                    </Col>
                                </Row>
                            </Container>
                            :

                            <div>
                                <Row>
                                    <Col sm={12} xs={12}>
                                        {
                                            list.map((i) => <CustomCard key={i.id} card={i} typeCard='remains'></CustomCard>)
                                        }
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <CustomPagination
                                            // getItemsforList={getItemsforList} 
                                            page={currentPage}
                                            setPage={setCurrentPage}
                                            pages={numbOfPages}>
                                        </CustomPagination>
                                    </Col>
                                </Row>
                            </div>
                    }
                </div>

            }

        </Container>
    );
}

export default RemainsPage;
