
export const firebaseConfig = {
    apiKey: "AIzaSyBqU8LZB9pBz8ct5BHKazOujdP7CFKrUkE",
    authDomain: "language-school-rsh.firebaseapp.com",
    projectId: "language-school-rsh",
    storageBucket: "language-school-rsh.appspot.com",
    messagingSenderId: "655074864379",
    appId: "1:655074864379:web:52cf5d6c5adfd85d4ed45e",
    measurementId: "G-E351SYZKWT",
    // storageBucket: 'gs://language-school-rsh.appspot.com/'
  };