import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';

import { setUser } from './authSlice';

import { useNavigate } from 'react-router-dom';

// import * as HandleData from './../../app/functions';

import Parse from 'parse';
import { ButtonGroup } from 'react-bootstrap';
Parse.initialize("HgZ11efHpJ67l9V9fjbPLnFECFBds9soS9NN8gu1", "E5KCSFDLfY3WDB5FyB6bIRIoWrRBJbfDJ6zO3gSy"); //PASTE HERE YOUR Back4App APPLICATION ID AND YOUR JavaScript KEY
Parse.serverURL = 'https://parseapi.back4app.com/';





// const app = initializeApp(firebaseConfig);
// const auth = getAuth(app);
// const db = getFirestore(app);

const schema = yup.object().shape({
    password: yup.string().min(6, 'Слишком короткий! (минимум 6)').max(10, 'Слишком длинный! (максимум 10)').required('Обязательное поле'),
    username: yup.string().min(4, 'Слишком короткое! (минимум 4)').max(16, 'Слишком длинное! (максимум 16)').required('Обязательное поле'),
});

const LoginPage = (props) => {

    const [image, setImage] = useState(null);
    // const user = useSelector((state) => state.Auth.user);
    // const isLogin = useSelector((state) => state.Auth.user.isLogin);

    const [errorLogin, setErrorLogin] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const getUser = async () => {
        const loggedInUser = await Parse.User.logIn('Roman_Shell', '123456');
        console.log(loggedInUser);

        let query = new Parse.Query('AvaGallery');
        const result = await query.find('userId', ' qaTDfbXgf3');
        console.log('From downloadImage ', result, result[0].get('picture').url());


    };



    const doUserLogIn = async function (data) {
        // Note that these values come from state variables that we've declared before
        const usernameValue = data.username;
        const passwordValue = data.password;
        try {
            const loggedInUser = await Parse.User.logIn(usernameValue, passwordValue);

            // console.log(loggedInUser, loggedInUser.id, loggedInUser.get('ava'));

            // let avaDownLoaded = '';
            // if (loggedInUser.get('ava')) {
            //     avaDownLoaded = await downloadImage('AvaGallery', loggedInUser.id);
            // };

            let tmpUser = {
                email: loggedInUser.get('email'),
                userId: loggedInUser.id,
                name: loggedInUser.get('username'),
                city: loggedInUser.get('city'),
                displayName: loggedInUser.get('username'),
                photoURL: '',
                isLogin: true,
                role: '',
                phone: loggedInUser.get('phone'),
                ava: loggedInUser.get('ava'),
                // emailVerified: loggedInUser.get('emailVerified')
            };

            dispatch(setUser(
                tmpUser
            ));

            alert(
                `Вход выполнен ! Пользователь ${loggedInUser.get(
                    'username'
                )} успешно вошел на сайт !`
            );
            // To verify that this is in fact the current user, `current` can be used
            
            // const currentUser = await Parse.User.current();
            
            
            // Clear input fields
            //   setUsername('');
            //   setPassword('');
            // Update state variable holding current user
            //   getCurrentUser();
            // props.setIsLogin(true);
            navigate('/auth');
            return true;
        } catch (error) {
            // Error can be caused by wrong parameters or lack of Internet connection
            alert(`Ошибка входа! ${error.message}`);
            navigate('/auth');
            return false;
        }
    };

    // const downloadImage = async (nameGallery, userId) => {
    //     try {
    //         let query = new Parse.Query(nameGallery);
    //         query.contains('userId', userId);
    //         let queryResult = await query.find();
    //         return queryResult[0].get('picture').url();
    //     } catch (error) {
    //         console.log('Ошибка загрузки аватарки ', error);
    //     }

    // };


    return (
        <div>
            <h2>ВХОД В АККАУНТ</h2>
            <Formik
                validationSchema={schema}
                onSubmit={values => doUserLogIn(values)}
                initialValues={{
                    username: '',
                    password: ''
                }}
            >
                {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    isValid,
                    errors,
                }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Row className="mb-3">
                            <Form.Group as={Col} md="4" controlId="validationFormik01">
                                <Form.Label>Login:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="username"
                                    value={values.username}
                                    onChange={handleChange}
                                    isValid={touched.username && !errors.username}
                                    isInvalid={!!errors.username}
                                />
                                <Form.Control.Feedback type="invalid">{errors.username}</Form.Control.Feedback>
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as={Col} md="4" controlId="validationFormik02">
                                <Form.Label>Password:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="password"
                                    value={values.password}
                                    onChange={handleChange}
                                    isValid={touched.password && !errors.password}
                                    isInvalid={!!errors.password}
                                />
                                <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            </Form.Group>
                        </Row>

                        <ButtonGroup>
                            <Button className='me-1 mt-2' variant="success" type="submit">ВХОД</Button>
                            <Button className="me-1 mt-2" variant="danger" onClick={() => navigate('/orders')}>
                                ОТМЕНА
                            </Button>
                        </ButtonGroup>


                    </Form>
                )}
            </Formik>
        </div>
    );
}

export default LoginPage;
