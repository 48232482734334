import React, { useState, useEffect } from 'react';
import Pagination from 'react-bootstrap/Pagination';

import './../App.css';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useSelector } from 'react-redux';

const CustomPagination = (props) => {

    const [items, setItems] = useState([]);
    const [activeItem, setActiveItem] = useState(1);
    const isLogin = useSelector((state) => state.Auth.user.isLogin);    
    // const [active, setActive] = useState(1);

    // console.log('The numb of pages from Parent = ' + props.pages);

    useEffect(() => {
        let tmpItems = [];
        for (let i = 1; i < props.pages + 1; i++) {
            tmpItems.push(
                <Button disabled={isLogin ? false : true} variant='dark' size='sm' className='rounded-0 me-1' key={i} active={i===props.page} onClick={() => handlePress(i)}>
                    {i}
                </Button>
            )
        };
        // console.log(tmpItems[0])
        setItems(tmpItems);
    }, []);    

    const handlePress = (n) => {        
       
        let tmpItems = [];
        for (let i = 1; i < props.pages + 1; i++) {
            tmpItems.push(
                <Button disabled={isLogin ? false : true} variant='dark' size='sm' className='rounded-0 me-1' key={i} active={i===props.page} onClick={() => handlePress(i)}>
                    {i}
                </Button>
            )
        };
        setItems(tmpItems);        
        props.setPage(n);
        
    };

    return (
        
        <OverlayTrigger overlay={isLogin ? <></> : <Tooltip id="tooltip-disabled">Нужно выполнить вход и подтвердить емеил...</Tooltip>}>
            <span className="d-inline-block">
                <Pagination className={'mt-2'} size="sm">{items}</Pagination>
            </span>
            
        </OverlayTrigger>
        
    );
}

export default CustomPagination;
