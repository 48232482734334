import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';




const UserCard = (props) => {
   
    return (
        <Card className='mt-2' style={{ width: '100%', }}>
          {
            props.user.ava !== '' ? <Card.Img variant="top" src={props.user.ava} />
            :
            null  
          }
          
          <Card.Body>
            <Card.Title>{props.user.name}</Card.Title>
            <Card.Text>{props.user.city}</Card.Text>
            <Card.Text>{props.user.phone}</Card.Text>
            <Button className='m-0' variant="warning" onClick={props.signOut}>ВЫХОД</Button>
            <Button className='ms-3' variant="danger">УДАЛИТЬ</Button>
          </Card.Body>
        </Card>
      );
    }

export default UserCard;
