import React from 'react';
import { useState } from 'react';

import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';

import { useSelector, useDispatch } from 'react-redux';
import { exitUser, setUser } from './authSlice';
import UserCard from '../../components/UserCard';
import { ButtonGroup, Container } from 'react-bootstrap';

import Parse from 'parse';
Parse.initialize("HgZ11efHpJ67l9V9fjbPLnFECFBds9soS9NN8gu1", "E5KCSFDLfY3WDB5FyB6bIRIoWrRBJbfDJ6zO3gSy"); //PASTE HERE YOUR Back4App APPLICATION ID AND YOUR JavaScript KEY
Parse.serverURL = 'https://parseapi.back4app.com/';




const AuthPage = (props) => {

    const user = useSelector((state) => state.Auth.user);    
    // const [active, setActive] = useState('login');


    const navigate = useNavigate();
    const dispatch = useDispatch();

    const signOutUser = async () => {
        try {
            await Parse.User.logOut();
            // To verify that current user is now empty, currentAsync can be used
            const currentUser = await Parse.User.current();
            if (currentUser === null) {
                alert(
                    `Выход выполнен !`
                );
                dispatch(exitUser());
            }
            // Update state variable holding current user
            props.setIsLogin(false);
            navigate('/auth');
            return true;
        } catch (error) {
            alert(`Error! ${error.message}`);
            navigate('/auth');
            return false;
        }        
    };

    return (
        <Container className='p-0'> 
            <h2>СТРАНИЦА ПРОФИЛЯ</h2>
            {
                !props.isLogin ?
                    <div>
                        <ButtonGroup>
                            <Button className='me-1 mt-2' variant="success" onClick={() => navigate('/login')}>
                                ВХОД
                            </Button>

                            <Button className="me-1 mt-2" variant="warning" onClick={() => navigate('/registration')}>
                                РЕГИСТРАЦИЯ
                            </Button>
                            <Button className="me-1 mt-2" variant="danger" onClick={() => navigate('/orders')}>
                                ОТМЕНА
                            </Button>

                        </ButtonGroup>
                    </div>

                    :
                    <div>
                        <UserCard signOut={signOutUser} user={user}></UserCard>
                    </div>
            }
        </Container>
    );
}

export default AuthPage;
