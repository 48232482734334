import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, BrowserRouter } from "react-router-dom";
import cards from './app/cards';

import Navigation from './components/Navigation';
import AppRouter from './components/AppRouter';
import { useSelector, useDispatch } from 'react-redux';
import { setUser, exitUser } from './pages/AuthPage/authSlice';


import Parse from 'parse'

Parse.initialize("HgZ11efHpJ67l9V9fjbPLnFECFBds9soS9NN8gu1", "E5KCSFDLfY3WDB5FyB6bIRIoWrRBJbfDJ6zO3gSy"); //PASTE HERE YOUR Back4App APPLICATION ID AND YOUR JavaScript KEY
Parse.serverURL = 'https://parseapi.back4app.com/'



function App() {

  const dispatch = useDispatch();
  const user = useSelector((state) => state.Auth.user);  
  const [isLogin, setIsLogin] = useState(false);


  useEffect(() => {

    const getCurrentUser = async function () {
      // console.log(Parse.User.current());
      try {
        if (Parse.User.current() !== null) {
          let currentUser = Parse.User.current();  
          // console.log(await currentUser.get('emailVerified'));       
          
          let tmpUser = {
            email: currentUser.get('email'),
            userId: currentUser.id,
            name: currentUser.get('username'),
            city: currentUser.get('city'),
            displayName: currentUser.get('username'),
            photoURL: '',
            isLogin: true,
            role: '',
            phone: currentUser.get('phone'),
            ava: currentUser.get('ava'),
            // emailVerified: await currentUser.get('emailVerified'),           
        };
        
        
        dispatch(setUser(
          tmpUser
        ));
          // console.log(Parse.User.current());
          setIsLogin(true);
        }
        else {
          dispatch(exitUser());
          setIsLogin(false);
        }

      } catch (error) {
        console.log('Ошибка входа на главной странице ', error);
      };      
    };
    getCurrentUser();
  }, []);


  return (
    <BrowserRouter>
      <Container>
        <Navigation isLogin={user.isLogin} userAva={user.ava}></Navigation>
        <AppRouter isLogin={user.isLogin} setIsLogin={setIsLogin}></AppRouter>
      </Container>
    </BrowserRouter>

  );
}

export default App;
