import React, { useEffect, useState } from 'react';
// import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
// import Card from '../components/CustomCard';


import CustomCard from '../components/CustomCard';

// import { useSelector } from 'react-redux';

import cards from '../app/cards';
import CustomPagination from '../ui/CustomPagination';
import SortPanel from '../ui/SortPanel';
import AddNewCard from './AddNewCard';
// import MiniUserCard from '../components/MiniUserCard';

import * as HandleData from './../app/functions'
// import { Button } from 'react-bootstrap';

import Parse from 'parse'
import { Container } from 'react-bootstrap';

Parse.initialize("HgZ11efHpJ67l9V9fjbPLnFECFBds9soS9NN8gu1", "E5KCSFDLfY3WDB5FyB6bIRIoWrRBJbfDJ6zO3gSy"); //PASTE HERE YOUR Back4App APPLICATION ID AND YOUR JavaScript KEY
Parse.serverURL = 'https://parseapi.back4app.com/'


const NUMBITEMS = 6;
// let pages = 0;
// let sortedItems = []; // Весть отсортированный список
// let copyData = []; // Временный обработанный условиями список

let sortObj = {};
// let count = 0;


// let date = 1680705910055;
// let count = 1000000;

const OrdersPage = (props) => {

    const [isLoading, setIsLoading] = useState(true);

    // const user = useSelector((state) => state.Auth.user);
    // console.log('Order Page user = ', user);


    // const [items, setItems] = useState([]);
    const [numbOfPages, setNumbOfPages] = useState(0);
    const [currentSort, setCurrentSort] = useState(cards.sortItems[0].sort); // Переменная для сортировки 
    const [currentClass, setCurrentClass] = useState(cards.classesWorks[0].class); // Переменная для отбора 
    const [currentPage, setCurrentPage] = useState(1);

    const [addCard, setAddCard] = useState(false);
    const [list, setList] = useState([]); // Отображаемый список (приходит с сервера отсортированным)

    useEffect(() => {
        HandleData.setLengthOfCollection("Order", NUMBITEMS, setNumbOfPages);
    }, []);

    useEffect(() => {
        const tryRead = async () => {
            setIsLoading(true);
            sortObj.nameOfCollection = 'Order';
            sortObj.class = currentClass;
            sortObj.start = 0;
            sortObj.page = currentPage;
            sortObj.limit = NUMBITEMS;
            sortObj.sortType = currentSort;
            try {
                setList(await HandleData.getData(sortObj, setNumbOfPages));
                setIsLoading(false);

            } catch (error) {
                console.log('UseEffect error: ', error);
            }

        };
        tryRead();

    }, [currentClass, currentSort, currentPage]);



    // const currentNumbOfPages = () => {
    //     let countPages = parseInt(cards.cardsOrders.length / NUMBITEMS);
    //     // console.log(countPages);
    //     return countPages + 1;
    // };



    // const addData = async () => { // Добавлял объекты вручную из массивов
    //     {
    //         const remain = new Parse.Object("Remain");
    //         let tmp = cards.cardsRemains[count];
    //         let sumcost = cards.cardsRemains[count].numb * cards.cardsRemains[count].cost;
    //         tmp.sumcost = sumcost;
    //         remain.set(tmp);
    //         try {
    //             let result = await remain.save()
    //             alert('New object created with objectId: ' + result.id);
    //         } catch (error) {
    //             alert('Failed to create new object, with error code: ' + error.message);
    //         }
    //         count++;
    //     };
    // };



    return (

        <Container className='p-0'> 
        {
            addCard ?
                <AddNewCard type={'Order'}
                    list={cards.classesWorks.slice(1)}
                    title={'ТИП РАБОТ: '}
                    setAddCard={setAddCard} >
                </AddNewCard> :
                <div>
                    <Row>
                        <SortPanel
                            isLogin={props.isLogin}
                            setAddCard={setAddCard}
                            leftTitle={currentClass}
                            rightTitle={currentSort}
                            leftItems={cards.classesWorks}
                            rightItems={cards.sortItems}
                            setCurrentSort={setCurrentSort}
                            setCurrentClass={setCurrentClass} >
                        </SortPanel>

                    </Row>
                    {/* <Button onClick={addData}>ADD</Button>
                    <Button onClick={getData}>GET</Button> */}

                    {
                        isLoading ?
                            <Container>
                                <Row className="justify-content-md-center mt-3 mb-3">
                                    <Col md="auto">
                                        <Spinner animation="border" role="status" />
                                    </Col>
                                </Row>
                                <Row className="justify-content-md-center mt-3">
                                    <Col md="auto">
                                        ЗАГРУЗКА...
                                    </Col>
                                </Row>
                            </Container>


                            :
                            <div>
                                <Row>
                                    <Col sm={12}
                                        xs={12} > {
                                            list.map((i) => <CustomCard key={i.id}
                                                card={i}
                                                typeCard='orders' > </CustomCard>)
                                        } </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <CustomPagination
                                            // getItemsforList={getItemsforList}
                                            setPage={setCurrentPage}
                                            page={currentPage}
                                            pages={numbOfPages}>
                                        </CustomPagination>
                                    </Col>
                                </Row>
                            </div>

                    }



                </div>
        }

        </Container>
    );
}

export default OrdersPage;