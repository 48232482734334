import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Container } from 'react-bootstrap';

const CustomModal = (props) => {

    return (
        <Container className='p-0'> 
            <Modal
                className='m-0'
                show={props.show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => props.setShow(false)}
            // aria-labelledby="contained-modal-title-vcenter"
            // centered
            >
                <Modal.Header closeButton>
                    {/* <Modal.Title>{props.card.title}</Modal.Title> */}
                </Modal.Header>

                <Card style={{ width: '100%', borderRadius: 0 }}>
                    {
                        props.card.img === 'none' ? null
                            :
                            <Card.Img src={props.card.img} style={{borderRadius: 0}}/>
                    }

                    <Card.Body>
                        <Card.Title>{props.card.class}</Card.Title>
                        <Card.Text>{props.card.discription}</Card.Text>
                        {
                            props.typeCard === 'contractors' ?
                                <div>
                                    <Card.Text>
                                        {props.card.location}
                                    </Card.Text>
                                    <Card.Text>
                                        {props.card.phone}
                                    </Card.Text>
                                </div>

                                :
                                <div>
                                    <Card.Text>
                                        {props.card.numb + ' ' + props.card.quant + '. Цена за ' + props.card.quant + '. ' + props.card.cost + ' грн.'
                                            + ' Общая стоимость: ' + props.card.sumcost
                                            + ' грн. '}
                                    </Card.Text>
                                    <Card.Text>
                                        {props.card.location}
                                    </Card.Text>
                                    <Card.Text>
                                        {props.card.phone}
                                    </Card.Text>
                                </div>

                        }
                        {/* <Button variant="primary">Go somewhere</Button> */}
                    </Card.Body>
                </Card>

            </Modal>
        </Container>


    );
}

export default CustomModal;
