// const res = [
//     require('./../assets/sourse/1.mp4'),
//     require('./../assets/sourse/2.mp4'),
//     require('./../assets/sourse/3.mp4'),
//     require('./../assets/sourse/4.mp4'),
//     require('./../assets/sourse/5.mp4'),
//     require('./../assets/sourse/6.mp4'),
// ]

const cards = {
    cardsOrders:
        [
            { id: 0, userID: '2Bq8jOri6LTtlu8bCy4iP6lrAz33', class: 'Фундаменты', title: "Свайный фундамент", discription: '42 буронабивные сваи, бурение, каркасы, бетонирование.', numb: 22, quant: 'м.куб', cost: 2000, phone: '050 381 72 64', location: 'Черкассы', date: 1681106910055 },
            { id: 1, userID: 'wK1mV2zeZIQwErU5L1FaAoMEYfd2', class: 'Металлоконструкции', title: "Монтаж МК Ангар", discription: 'Нужно смонтировать каркас ангара. Три ряда колонн, фермы, прогоны. Масса около 14 тонн.', numb: 14, quant: 'тонн', cost: 8000, phone: '050 381 72 64', location: 'Черкассы', date: 1680791760055 },
            { id: 2, userID: 'OVXbzItad5ScjmbWRXETdTSnUMF3', class: 'Металлоконструкции', title: "Изготовление МК Ангар", discription: 'Ищем подрядчика для изготовления конструкции ангара. Есть территория для работ. Изготовление предпочительно на нашем участке.', numb: 14, quant: 'тонн', cost: 15000, phone: '050 38 172 64', location: 'Черкассы', date: 1680908910055 },
            { id: 3, userID: 'wK1mV2zeZIQwErU5L1FaAoMEYfd2', class: 'Кровли', title: "Наплавка рубероида", discription: 'Наплавка рубероида в два слоя с праймером. Перед наплавкой нужно демонтировать старое покрытие и отремонтировать частично стяжку.', numb: 720, quant: 'м.кв', cost: 140, phone: '050 381 72 64', location: 'Черкассы', date: 1680995910055 },
            { id: 4, userID: '2Bq8jOri6LTtlu8bCy4iP6lrAz33', class: 'Фундаменты', title: "Ростверки 400 х 400", discription: 'По готовым сваям нужно выполнить ростверки около 120 метров погонных, 40х40 см, опалубка есть.', numb: 12, quant: 'м.куб', cost: 3000, phone: '050 381 72 64', location: 'Черкассы', date: 1682316910055 },
            { id: 5, userID: 'wK1mV2zeZIQwErU5L1FaAoMEYfd2', class: 'Фундаменты', title: "Сваи буронабивные", discription: 'Интересует устройство свайного поля. 36 свай, 500 мм, 7 метров глубина.', numb: 36, quant: 'м.куб', cost: 2500, phone: '050 381 72 64', location: 'Черкассы', date: 1683426910055 },
            { id: 6, userID: '2Bq8jOri6LTtlu8bCy4iP6lrAz33', class: 'Стены', title: "Кладка стен", discription: 'Черновая кладка стен, 80 кубов...', numb: 80, quant: 'м.куб', cost: 1500, phone: '0503817264', location: 'Черкассы', date: 1683836910055 },
            { id: 7, userID: 'wK1mV2zeZIQwErU5L1FaAoMEYfd2', class: 'Полы', title: "Устройство полов", discription: 'Нужен промышленный пол 1200 м.кв. 200 мм толщина, одна сетка из 12 арматуры, 200х200 мм.', numb: 1200, quant: 'м.кв', cost: 120, phone: '050 381 72 64', location: 'Черкассы', date: 1684346910055 },
            { id: 8, userID: '2Bq8jOri6LTtlu8bCy4iP6lrAz33', class: 'Кровли', title: "Монтаж профнастила", discription: 'Монтаж кровельного профнастила. Высота 9 метров, двускатная кровля.', numb: 200, quant: 'м.кв', cost: 150, phone: '050 381 72 64', location: 'Черкассы', date: 1685456910055 },
            { id: 9, userID: '2Bq8jOri6LTtlu8bCy4iP6lrAz33', class: 'Металлоконструкции', title: "Демонтаж навеса", discription: 'Требуется бригада с инструментом для демонтажа резаком каркаса навеса. Кран наш.', numb: 5, quant: 'тонн', cost: 5000, phone: '050 381 72 64', location: 'Черкассы', date: 1685966910055 },
            { id: 10, userID: 'OVXbzItad5ScjmbWRXETdTSnUMF3', class: 'Стены', title: "Кладка газоблока", discription: 'Двух этажный частный дом. Нужны каменщики на кладку газоблока. Нужны леса.', numb: 64, quant: 'м.куб', cost: 1200, phone: '050 381 72 64', location: 'Черкассы', date: 1686176910055 },
            { id: 11, userID: '2Bq8jOri6LTtlu8bCy4iP6lrAz33', class: 'Стены', title: "Кладка кирпича", discription: 'Кладка стен, гараж. Высота до 3 метров.', numb: 8, quant: 'м.куб', cost: 1300, phone: '0503817264', location: 'Черкассы', date: 1686486910055 },
            { id: 12, userID: '2Bq8jOri6LTtlu8bCy4iP6lrAz33', class: 'Фундаменты', title: "Изготовить ленточный фундамент", discription: '10 кубов подошва, 8 кубов стенка 1,2 метра высота, 40 см. толщина.', numb: 18, quant: 'м.куб', cost: 2000, phone: '050 381 72 64', location: 'Черкассы', date: 1686696910055 },
            { id: 13, userID: 'wK1mV2zeZIQwErU5L1FaAoMEYfd2', class: 'Фасады', title: "Фасадные работы", discription: 'Нужна бригада фасадчиков с лесами. Высота стен - 4 метра. Пенопласт, сетка, штукатурка, покраска.', numb: 220, quant: 'м.кв', cost: 300, phone: '050 381 72 64', location: 'Черкассы', date: 1686906919055 },
            { id: 14, userID: 'wK1mV2zeZIQwErU5L1FaAoMEYfd2', class: 'Земляные работы', title: "Рытье котлована", discription: '600 кубов, глубина до 3 метров. Желательно поворотный экскаватор. Грунт не нужен, можно вывозить, с ценой определимся.', numb: 600, quant: 'м.куб', cost: 100, phone: '050 381 72 64', location: 'Черкассы', date: 1687706977055 },
            { id: 15, userID: '2Bq8jOri6LTtlu8bCy4iP6lrAz33', class: 'Отделка', title: "Штукатурка помещений цеха", discription: 'Штукатурка стен цеха после пожара. Демонтаж старой штукатурка, нанесение новой. Уже можно приступать. Желательно машинный способ, цементно-песчаная.', numb: 540, quant: 'м.кв', cost: 150, phone: '050 381 72 64', location: 'Черкассы', date: 1687906000055 },
            { id: 16, userID: 'OVXbzItad5ScjmbWRXETdTSnUMF3', class: 'Стены', title: "Кладка стен из газоблока", discription: 'Стены магазина. Перестенки внутри. 2 этажа. Около 42 кубов кладки блока. Дополнительно перемычки, плиты перекрытия.', numb: 42, quant: 'м.куб', cost: 1000, phone: '050 381 72 64', location: 'Черкассы', date: 1689006919955 },
            { id: 17, userID: '2Bq8jOri6LTtlu8bCy4iP6lrAz33', class: 'Отделка', title: "Кладка шлакоблока", discription: 'Кладка шлакоблока, гараж.', numb: 6, quant: 'м.куб', cost: 900, phone: '050 381 72 64', location: 'Черкассы', date: 1688506660055  },
            { id: 18, userID: 'wK1mV2zeZIQwErU5L1FaAoMEYfd2', class: 'Водопровод, канализация', title: "Устройство септика", discription: 'Врезаться в имеющуюся систему, добавить септик. Глубина - 4 кольца. Дно бетонируем.', numb: 1, quant: 'шт.', cost: 5000, phone: '050 381 72 64', location: 'Черкассы', date: 1688506660055 },
            { id: 19, userID: 'OVXbzItad5ScjmbWRXETdTSnUMF3', class: 'Отделка', title: "Укладка плитки на пол", discription: 'Нужен плиточник для укладки плитки. Квартира 2 комнатная. Плитка 300х300.', numb: 48, quant: 'м.кв', cost: 350, phone: '050 381 72 64', location: 'Черкассы', date: 1688906000055 },
            { id: 20, userID: 'wK1mV2zeZIQwErU5L1FaAoMEYfd2', class: 'Металлоконструкции', title: "Монтаж каркаса из швеллера", discription: 'Есть разобранный навес. Колонны - швеллер 14 в коробке, балки и прогоны 24 и 12 швеллер. Нужен монтаж. Предложите цену за тонну с краном.', numb: 2, quant: 'тонн', cost: 7000, phone: '050 381 72 64', location: 'Черкассы', date: 1689006919955 },

        ],
    cardsContractors:
        [
            { id: 0, userID: '2Bq8jOri6LTtlu8bCy4iP6lrAz33', class: 'Общестрой', title: "ТОВ Стройлюкс 2000", discription: 'Специализируется на строительстве жилых домов, коммерческих зданий и промышленных объектов. Гарантирует высокое качество работ и соблюдение сроков. Работает с использованием современных технологий и материалов.', stars: 5, phone: '050 381 72 64', location: 'Черкассы', quant: 12, date: 1680706220055 },
            { id: 1, userID: 'wK1mV2zeZIQwErU5L1FaAoMEYfd2', class: 'Кровельщики', title: "ЕвроКровля", discription: 'Наша команда состоит из квалифицированных специалистов, которые готовы решить любую проблему, связанную с крышей.', cost: 125000, stars: 1, phone: '050 381 72 64', location: 'Черкассы', quant: 20, date: 1680706933055 },
            { id: 2, userID: '2Bq8jOri6LTtlu8bCy4iP6lrAz33', class: 'Фундаменты', title: "Бригада по фундаментам", discription: 'Компания "ФундаментСтрой" предоставляет профессиональные услуги по строительству фундаментов любой сложности. Наша команда состоит из опытных специалистов, которые знают все нюансы строительства фундаментов. Мы используем только качественные материалы и обеспечиваем высокий уровень работы.', stars: 4, phone: '050 381 72 64', location: 'Черкассы', quant: 8, date: 1680806120055 },
            { id: 3, userID: 'OVXbzItad5ScjmbWRXETdTSnUMF3', class: 'Фасадчики', title: "Фасад Импульс", discription: 'Компания "ФасадПро" предоставляет услуги по отделке и ремонту фасадов зданий любой сложности. Наша команда имеет многолетний опыт в этой области и может выполнить работы любой сложности. Мы используем только высококачественные материалы и обеспечиваем высокий уровень работы.', stars: 3, phone: '050 381 72 64', location: 'Черкассы', quant: 34, date: 1681004410055 },
            { id: 4, userID: 'wK1mV2zeZIQwErU5L1FaAoMEYfd2', class: 'Общестрой', title: "СМУ-21", discription: 'Специализируется на проведении экспертизы строительных работ и оценке качества материалов. Предоставляет услуги по контролю за строительством и исполнению проекта в соответствии с действующими нормами и стандартами. Работает с проектами любой сложности и масштаба.', stars: 0, phone: '050 381 72 64', location: 'Черкассы', quant: 10, date: 1681406310055 },
            { id: 5, userID: '2Bq8jOri6LTtlu8bCy4iP6lrAz33', class: 'Металлоконструкции', title: "ПП Металл Инжиниринг", discription: 'Компания "МеталлПрофи" специализируется на производстве и монтаже металлоконструкций любой сложности. Наша команда состоит из высококвалифицированных специалистов, которые имеют большой опыт в этой области. Мы гарантируем качество наших изделий и монтажных работ.', stars: 3, phone: '050 381 72 64', quant: 28, date: 1681707810055 },
            { id: 6, userID: 'wK1mV2zeZIQwErU5L1FaAoMEYfd2', class: 'Кровельщики', title: "Бригада кровельщиков", discription: 'Мы - кровельная компания, специализирующаяся на установке и ремонте крыш. Наша команда состоит из опытных специалистов, которые готовы выполнить любые кровельные работы на высоком уровне. Мы используем только высококачественные материалы, чтобы гарантировать долговечность и надежность крыши. Мы предоставляем гарантию на все наши работы, чтобы наши клиенты были уверены в качестве услуг, которые мы предоставляем.', stars: 3, phone: '050 381 72 64', location: 'Черкассы', quant: 6, date: 1682006999055 },
            { id: 7, userID: '2Bq8jOri6LTtlu8bCy4iP6lrAz33', class: 'Фундаменты', title: "СтройТех", discription: 'Компания "ФундаментМастер" специализируется на строительстве и ремонте фундаментов. Наша команда имеет большой опыт в этой области и может выполнить работы любой сложности. Мы гарантируем качество и надежность наших работ.', cost: 50000, stars: 1, phone: '050 381 72 64', location: 'Черкассы', quant: 40, date: 1682306030055 },
            { id: 8, userID: 'wK1mV2zeZIQwErU5L1FaAoMEYfd2', class: 'Общестрой', title: "Будинвест", discription: 'Компания "СтройМастер" является профессионалом в области общестроительных работ. Мы предоставляем услуги по строительству жилых и коммерческих объектов, ремонту и отделке помещений, монтажу инженерных систем и другим видам работ. Наша команда состоит из квалифицированных специалистов, которые гарантируют качество выполненных работ в соответствии с требованиями и пожеланиями клиентов. Мы используем современные технологии и качественные материалы для достижения наилучших результатов.', stars: 2, phone: '050 381 72 64', location: 'Черкассы', quant: 35, date: 1682506200055 },
            { id: 9, userID: '2Bq8jOri6LTtlu8bCy4iP6lrAz33', class: 'Металлоконструкции', title: "Монтажники металлоконструкций", discription: 'Компания "МеталлМастер" занимается изготовлением и монтажом металлоконструкций для промышленных, складских и жилых объектов. Наша команда имеет большой опыт и может выполнить работы любой сложности. Мы используем только качественные материалы и гарантируем качество наших работ.', cost: 80000, stars: 0, phone: '050 381 72 64', location: 'Черкассы', quant: 30, date: 1683006220055 },
            { id: 10, userID: 'OVXbzItad5ScjmbWRXETdTSnUMF3', class: 'Общестрой', title: "Будмастер 2", discription: 'Занимается проектированием и строительством жилых и коммерческих объектов. Имеет большой опыт работы с заказчиками из разных отраслей. Предлагает индивидуальный подход к каждому проекту.', cost: 20000, stars: 1, phone: '050 381 72 64', location: 'Черкассы', quant: 50, date: 1683506949955 },
            { id: 11, userID: '2Bq8jOri6LTtlu8bCy4iP6lrAz33', class: 'Земляные работы', title: "Котлованы под ключ", discription: 'Компания "ЗемляСтрой" является профессионалом в области земляных работ. Мы предоставляем услуги по разработке котлованов, земляному копанию, вывозу и утилизации отходов, подготовке площадок под строительство и другим видам работ. Наша команда состоит из опытных специалистов, которые имеют большой опыт работы в данной области. Мы используем современное оборудование и технологии для выполнения задач любой сложности. Мы гарантируем качество наших работ и соблюдение сроков выполнения заказов.', stars: 1, phone: '050 381 72 64', location: 'Черкассы', quant: 22, date: 1683806080055 },
            { id: 12, userID: 'wK1mV2zeZIQwErU5L1FaAoMEYfd2', class: 'Благоустройство', title: "ЕвроДизайн", discription: 'Наша компания "Зеленый Мир" занимается профессиональным благоустройством территорий. Мы предлагаем широкий спектр услуг, включая ландшафтный дизайн, устройство газонов и цветников, посадку деревьев и кустарников, установку садовой мебели и другие виды работ. Наша команда состоит из опытных специалистов, которые имеют большой опыт работы в данной области. Мы используем только качественные материалы и современное оборудование для достижения наилучших результатов.', stars: 4, phone: '050 381 72 64', location: 'Черкассы', quant: 10, date: 1684506390055 },
            { id: 13, userID: '2Bq8jOri6LTtlu8bCy4iP6lrAz33', class: 'Благоустройство', title: "Ваш край", discription: 'Наша бригада "Территория Красоты" занимается профессиональным благоустройством территорий. Мы предлагаем услуги по обустройству газонов, установке систем полива, уходу за растениями, стрижке газонов и другим видам работ. Наша команда состоит из опытных специалистов, которые гарантируют качество выполненных работ и соблюдение сроков. Мы используем только качественные материалы и современное оборудование для достижения наилучших результатов.', stars: 0, phone: '050 381 72 64', location: 'Черкассы', quant: 25, date: 1684808110055 },
            { id: 14, userID: 'wK1mV2zeZIQwErU5L1FaAoMEYfd2', class: 'Фасадчики', title: "Мастера фасадов", discription: 'Компания "ФасадЭксперт" специализируется на выполнении фасадных работ любой сложности. Наша команда состоит из опытных специалистов, которые готовы выполнить любые задачи на профессиональном уровне. Мы используем только качественные материалы и гарантируем качество наших работ.', cost: 10000, stars: 2, phone: '050 381 72 64', location: 'Черкассы', quant: 14, date: 1685006440055 },
            { id: 15, userID: 'OVXbzItad5ScjmbWRXETdTSnUMF3', class: 'Кровельщики', title: "ПП Зинченко", discription: 'Профессиональная кровельная компания, которая предоставляет услуги по установке и ремонту крыш любой сложности. Мы имеем многолетний опыт работы в этой области и готовы выполнить любые кровельные работы, используя только качественные материалы. Наша команда состоит из квалифицированных специалистов, которые готовы решить любую проблему, связанную с крышей.', stars: 1, phone: '050 381 72 64', location: 'Черкассы', quant: 20, date: 1685406010055 },
            { id: 16, userID: 'wK1mV2zeZIQwErU5L1FaAoMEYfd2', class: 'Каменщики', title: "Кладка на все 100", discription: 'Наша бригада каменщиков "Каменщики-Профи" специализируется на возведении стен из кирпича, блока, камня и других материалов. Мы имеем большой опыт в работе с различными материалами и предоставляем услуги строительства и отделки каменных конструкций любой сложности. Наша команда состоит из квалифицированных специалистов, которые гарантируют качество выполненных работ и соблюдение сроков. Мы используем только качественные материалы и современное оборудование для достижения наилучших результатов.', stars: 5, phone: '050 381 72 64', location: 'Черкассы', quant: 16, date: 1685606583055 }
        ],
    cardsRemains:
        [
            { id: 0, userID: '2Bq8jOri6LTtlu8bCy4iP6lrAz33', class: 'Арматура, проволока, прут', discription: 'Продам арматуру.', title: "Арматура 12, 14", numb: 22, quant: 'кг.', cost: 25, phone: '050 381 72 64', location: 'Черкассы', date: 1680736910055 },
            { id: 1, userID: 'OVXbzItad5ScjmbWRXETdTSnUMF3', class: 'Цемент, штукатурка, клей', discription: 'Остатки клея Церазит.', title: "Клей Ceresit", numb: 14, quant: 'шт.', cost: 100, phone: '050 381 72 64', location: 'Черкассы', date: 1680776910055 },
            { id: 2, userID: 'wK1mV2zeZIQwErU5L1FaAoMEYfd2', class: 'Песок, щебень, отсев', discription: 'Отсев, самовывоз.', title: "Отсев", numb: 14, quant: 'м.куб.', cost: 800, phone: '050 381 72 64', location: 'Черкассы', date: 1680806910055 },
            { id: 3, userID: '2Bq8jOri6LTtlu8bCy4iP6lrAz33', class: 'ФБС, плиты, кирпич, блоки', discription: 'Продам красный кирпич 800 штук, на поддонах.', title: "Кирпич красный", numb: 800, quant: 'шт.', cost: 5, phone: '050 381 72 64', location: 'Черкассы', date: 1680826910055 },
            { id: 4, userID: 'wK1mV2zeZIQwErU5L1FaAoMEYfd2', class: 'Краска, грунтовка', discription: 'Ореол, водоэмульсионка, продам. 3 штуки.', title: "Краска Ореол", numb: 3, quant: 'шт.', cost: 250, phone: '050 381 72 64', location: 'Черкассы', date: 1680876910055 },
            { id: 5, userID: 'OVXbzItad5ScjmbWRXETdTSnUMF3', class: 'Арматура, проволока, прут', discription: '8 арматура НДЛ остатки.', title: "Арматура 8", numb: 360, quant: 'кг.', cost: 22, phone: '050 381 72 64', location: 'Черкассы', date: 1680906910055 },
            { id: 6, userID: '2Bq8jOri6LTtlu8bCy4iP6lrAz33', class: 'Песок, щебень, отсев', discription: 'Песок речной 5 кубов.', title: "Песок речной", numb: 5, quant: 'м.куб', cost: 900, phone: '050 381 72 64', location: 'Черкассы', date: 1680926910055 },
            { id: 7, userID: 'OVXbzItad5ScjmbWRXETdTSnUMF3', class: 'Арматура, проволока, прут', discription: 'Две бухты вязалки.', title: "Проволока вязальная", numb: 32, quant: 'кг.', cost: 30, phone: '050 381 72 64', location: 'Черкассы', date: 1680956910055 },
            { id: 8, userID: 'wK1mV2zeZIQwErU5L1FaAoMEYfd2', class: 'Песок, щебень, отсев', discription: 'Не использованный песок 4 куба.', title: "Песок горный", numb: 4, quant: 'м.куб', cost: 700, phone: '050 381 72 64', location: 'Черкассы', date: 1680976910055 },
            { id: 9, userID: '2Bq8jOri6LTtlu8bCy4iP6lrAz33', class: 'Краска, грунтовка', discription: 'Фасадная краска на 100-150 м.кв.', title: "Краска Снежка", numb: 1, quant: 'шт.', cost: 800, phone: '050 381 72 64', location: 'Черкассы', date: 1680996910055},
            { id: 10, userID: 'wK1mV2zeZIQwErU5L1FaAoMEYfd2', class: 'Цемент, штукатурка, клей', discription: 'Штукатурка для наружного применения.', title: "Штукатурка Siltek", numb: 6, quant: 'шт.', cost: 90, phone: '050 381 72 64', location: 'Черкассы', date: 1681706910055 },
            { id: 11, userID: 'wK1mV2zeZIQwErU5L1FaAoMEYfd2', class: 'Цемент, штукатурка, клей', discription: '8 мешков Будматера штукатурка.', title: "Штукатурка Будмастер", numb: 8, quant: 'шт.', cost: 110, phone: '050 381 72 64', location: 'Черкассы', date: 1681806910055 },
            { id: 12, userID: '2Bq8jOri6LTtlu8bCy4iP6lrAz33', class: 'Двутавр, швеллер, уголок', discription: '10 метров швеллера 14, куски по 2 метра 5 штук.', title: "Швеллер 14", numb: 120, quant: 'кг.', cost: 40, phone: '050 381 72 64', location: 'Черкассы', date: 1681906910055 },
            { id: 13, userID: 'wK1mV2zeZIQwErU5L1FaAoMEYfd2', class: 'Краска, грунтовка', discription: 'Церазит грунт, 8 банок.', title: "Грунтовка Ceresit", numb: 8, quant: 'шт.', cost: 250, phone: '050 381 72 64', location: 'Черкассы', date: 1682006910055 },
            { id: 14, userID: '2Bq8jOri6LTtlu8bCy4iP6lrAz33', class: 'ФБС, плиты, кирпич, блоки', discription: 'Аэрок, два поддона.', title: "Газоблоки 200", numb: 600, quant: 'шт.', cost: 55, phone: '050 381 72 64', location: 'Черкассы', date: 1682106910055 },
            { id: 15, userID: 'OVXbzItad5ScjmbWRXETdTSnUMF3', class: 'ФБС, плиты, кирпич, блоки', discription: 'Шлакоблоки, бу, очищенные. Как новые.', title: "Шлакоблоки", numb: 40, quant: 'шт.', cost: 45, phone: '050 381 72 64', location: 'Черкассы', date: 1682206910055 },
            { id: 16, userID: 'wK1mV2zeZIQwErU5L1FaAoMEYfd2', class: 'Краска, грунтовка', discription: 'Заберите 1 банку грунта. 50 процентов от стоимости.', title: "Грунтовка ГФ-21", numb: 1, quant: 'шт.', cost: 80, phone: '050 381 72 64', location: 'Черкассы', date: 1682306910055 },
            { id: 17, userID: '2Bq8jOri6LTtlu8bCy4iP6lrAz33', class: 'Электрика', discription: 'После монтаж электрики, остатки провода 2,5 мм.', title: "Провод медный 2 жильный 2,5 мм", numb: 45, quant: 'м.п.', cost: 12, phone: '050 381 72 64', location: 'Черкассы', date: 1682406910055 },
            { id: 18, userID: 'wK1mV2zeZIQwErU5L1FaAoMEYfd2', class: 'Расходники, круги, электроды', discription: 'Хорошие электроды Монолит. Пять пачек, запечатанные.', title: "Электроды Монолит 3 мм", numb: 5, quant: 'шт.', cost: 250, phone: '050 381 72 64', location: 'Черкассы', date: 1682606910055 },
        ],
    classesWorks: 
        [
            { id: 0, class: 'Все работы' },
            { id: 1, class: 'Фундаменты' },
            { id: 2, class: 'Металлоконструкции' },        
            { id: 3, class: 'Кровли' },        
            { id: 4, class: 'Стены' },
            { id: 5, class: 'Полы' },
            { id: 6, class: 'Фасады' },
            { id: 7, class: 'Земляные работы' },
            { id: 8, class: 'Отделка' },
            { id: 9, class: 'Водопровод, канализация' },
            { id: 10, class: 'Другие работы' },        
        ],
    sortItems: 
        [
            { id: 0, sort: 'Сначала новее', code: 'asc' },
            { id: 1, sort: 'Сначала позднее', code: 'desc' },        
            { id: 2, sort: 'Сначала дороже', code: 'asc' },        
            { id: 3, sort: 'Сначала дешевле', code: 'desc' }          
        ], 
    classesRemains: 
        [
            { id: 0, class: 'Все материалы' },
            { id: 1, class: 'Арматура, проволока, прут' },
            { id: 2, class: 'Цемент, штукатурка, клей' },
            { id: 3, class: 'Песок, щебень, отсев' },
            { id: 4, class: 'ФБС, плиты, кирпич, блоки' },
            { id: 5, class: 'Краска, грунтовка' },
            { id: 6, class: 'Двутавр, швеллер, уголок' },            
            { id: 7, class: 'Электрика' },
            { id: 8, class: 'Расходники, круги, электроды' },   
        ],

        locations:
        [
            { id: 0, location: 'Черкассы' },
            { id: 1, location: 'Киев' },
            { id: 2, location: 'Винница' },
            { id: 3, location: 'Днепр' },
            { id: 4, location: 'Донецк' },
            { id: 5, location: 'Житомир' },
            { id: 6, location: 'Запорожье' },
            { id: 7, location: 'Ивано-Франковск' },
            { id: 8, location: 'Кропивницкий' },
            { id: 9, location: 'Луганск' },
            { id: 10, location: 'Луцк' },
            { id: 11, location: 'Львов' },
            { id: 12, location: 'Николаев' },
            { id: 13, location: 'Одесса' },
            { id: 14, location: 'Полтава' },
            { id: 15, location: 'Ровно' },
            { id: 16, location: 'Сумы' },
            { id: 17, location: 'Тернополь' },
            { id: 18, location: 'Ужгород' },
            { id: 19, location: 'Харьков' },
            { id: 20, location: 'Херсон' },
            { id: 21, location: 'Хмельницкий' },           
            { id: 22, location: 'Чернигов' },
            { id: 23, location: 'Черновцы' }
        ],

        quants: 
        [
            { id: 0, quant: 'м.кв.' },
            { id: 1, quant: 'м.куб.' },
            { id: 2, quant: 'м.п.' },
            { id: 3, quant: 'тонн' },
            { id: 4, quant: 'шт.' },            
        ]
}
export default cards;
