import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Row from 'react-bootstrap/Row';
import { Formik } from 'formik';
import * as formik from 'formik';
import * as yup from 'yup';
import cards from '../app/cards';

import { useSelector } from 'react-redux';

import { initializeApp } from "firebase/app";
import { getAuth, signOut, onAuthStateChanged, updateProfile, deleteUser } from "firebase/auth";
// import { getFirestore } from "firebase/firestore";
// import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
// import { collection, query, where, deleteDoc, setDoc } from "firebase/firestore";

import { firebaseConfig } from './../app/firebaseConfig'
// import { addDoc, getDocs, getCountFromServer } from "firebase/firestore";
// import { doc, getDoc, getDocs } from "firebase/firestore";

import * as HandleData from './../app/functions';
import Parse from 'parse';
import { Container } from 'react-bootstrap';

Parse.initialize("HgZ11efHpJ67l9V9fjbPLnFECFBds9soS9NN8gu1", "E5KCSFDLfY3WDB5FyB6bIRIoWrRBJbfDJ6zO3gSy"); //PASTE HERE YOUR Back4App APPLICATION ID AND YOUR JavaScript KEY
Parse.serverURL = 'https://parseapi.back4app.com/'


// import { getMessaging } from "firebase/messaging";
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

// const db = getFirestore(app);


const AddNewCard = (props) => {

    const [image, setImage] = useState('');

    const user = useSelector((state) => state.Auth.user);
    console.log('Add New Page user = ', user);

    const schema = yup.object().shape({
        class: yup.string().required('Обязательное поле'),
        title: yup.string().min(12, 'Не менее 12 символов').max(30, 'Не более 30 символов').required('Обязательное поле'),
        discr: yup.string().min(12, 'Не менее 12 символов').max(500, 'Не более 500 символов').required('Обязательное поле'),
        location: yup.string().required('Обязательное поле'),
        phone: yup.string().required('Обязательное поле'),
        username: yup.string().required('Обязательное поле'),
        quant: yup.string().required('Обязательное поле'),
        numb: yup.number().typeError('Целое число').positive('Не меньше 1').integer('Целое число').min(1, 'Не меньше 1').max(100000, 'Не больше 100 000').required('Обязательное поле'),
        cost: yup.number().typeError('Целое число').positive('Не меньше 1').integer('Целое число').min(1, 'Не меньше 1').max(100000, 'Не больше 100 000').required('Обязательное поле'),
        // file: yup.mixed(),       
    });


    const [validated, setValidated] = useState(false);

    const addCard = (values) => {
        // console.log('ADD');
        if (values.title === '' || values.discr === '' || values.cost === '' || values.quant === '' || values.location === '' || values.class === '' || values.username === '' || values.phone === '') {
            // console.log(values);
            console.log('Не все поля заполнеы');
        } else {
            // const tmpId = Math.random().toString(36).substring(6);
            // console.log(values)
            addDataToBase(
                {
                    type: props.type,
                    values,
                    // tmpId,                
                }
            );
            // console.log(values);
        }
    };


    const addDataToBase = async (newData) => {
        let avaDownLoaded = { url: 'none', id: 'none' };
        let tmpObject = {
            // id: newData.tmpId,                
            date: Date.now(),
            class: newData.values.class,
            title: newData.values.title,
            cost: parseInt(newData.values.cost),
            discription: newData.values.discr,
            location: newData.values.location,
            numb: parseInt(newData.values.numb),
            quant: newData.values.quant,
            sumcost: newData.values.cost * newData.values.numb,
            phone: user.phone,
            userID: user.userId,
            img: avaDownLoaded.url,
            imgId: avaDownLoaded.id
        };

        if (image !== 'none') {

            console.log('Before ', image)
            let compressedImage = await HandleData.compressImage(image);
            console.log('After ', compressedImage);
            avaDownLoaded = await HandleData.uploadImage('OrderGallery', compressedImage);
            // avaDownLoaded = await downloadImage('AvaGallery', userId); 
            tmpObject.img = avaDownLoaded.url;
            tmpObject.imgId = avaDownLoaded.id;

            // user.set("ava", avaDownLoaded.url);
            // user.set("imgId", avaDownLoaded.id);
        };


        try {
            const base = new Parse.Object(newData.type);
            base.set(tmpObject);
            try {
                let result = await base.save()
                alert('New object created with objectId: ' + result.id);
            } catch (error) {
                alert('Failed to create new object, with error code: ' + error.message);
            }

        } catch (error) {
            console.log('Ошибка добавления в базу', error);
        }


        // try {
        //     const dataRef = collection(db, newData.type);
        //     await setDoc(doc(dataRef, newData.tmpId), {
        //         id: newData.tmpId,                
        //         date: Date.now(),                
        //         class: newData.values.class,
        //         title: newData.values.title,
        //         cost: newData.values.cost,
        //         discription: newData.values.discr,
        //         location: newData.values.location,
        //         numb: newData.values.numb,
        //         quant: newData.values.quant,
        //         phone: user.phone,
        //         userId: user.userId        
        //     });

        //     // const docRef = await addDoc(collection(db, "users"), {
        //     //     id: id,
        //     //     email: email,
        //     //     date: Date.now(),
        //     //     role: role,
        //     //     name: '',
        //     //     surname: ''
        //     // });

        // console.log("Document written with ID: ", docRef.id);
        // } catch (e) {
        //     console.error("Error adding document: ", e);
        // }
        props.setAddCard(false);
    };


    return (
        <Container className='p-0 mt-2'>
            <Formik
                validationSchema={schema}
                onSubmit={addCard}
                initialValues={{
                    class: props.list[0].class,
                    title: '',
                    discr: '',
                    location: cards.locations[0].location,
                    phone: '050 381 72 64',
                    username: 'Roman',
                    numb: 1,
                    cost: 1,
                    quant: cards.quants[0].quant,
                    file: null,
                }}
            >
                {({ handleSubmit, handleChange, values, touched, errors }) => (
                    <Form noValidate validated={validated} onSubmit={

                        (e) => {
                            console.log(errors, e);
                            e.preventDefault();
                            handleSubmit();
                            // console.log(values);

                            if (!errors) {
                                console.log('Was add = ', values);
                            }
                            else {
                                console.log(errors);
                            }

                        }
                    }>
                        <Row className="mb-3">
                            <Form.Group as={Col} md="4" controlId="validationCustom01">
                                <Form.Label>{props.title}</Form.Label>
                                <Form.Select
                                    name='class'
                                    value={values.class}
                                    onChange={handleChange}
                                    isInvalid={!!errors.class}
                                >
                                    {
                                        props.list.map(c => <option key={c.id}>{c.class}</option>)
                                    }
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    {errors.class}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} md="3" controlId="validationCustom03">
                                <Form.Label>ГОРОД:</Form.Label>
                                <Form.Select
                                    name='location'
                                    value={values.location}
                                    onChange={handleChange}
                                    isInvalid={!!errors.location}
                                >
                                    {
                                        cards.locations.map(l => <option key={l.id}>{l.location}</option>)
                                    }
                                </Form.Select>

                                <Form.Control.Feedback type="invalid">
                                    {errors.location}
                                </Form.Control.Feedback>
                            </Form.Group>



                            <Form.Group as={Col} md="5" controlId="validationCustom02">
                                <Form.Label>ЗАГОЛОВОК (до 30 букв):</Form.Label>
                                <Form.Control
                                    name='title'
                                    value={values.title}
                                    onChange={handleChange}
                                    // formNoValidate={touched.title && errors.title}
                                    type="text"
                                    placeholder="Опишите"
                                    isInvalid={!!errors.title}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.title}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>

                        <Row className="mb-3">
                            <Form.Group as={Col} md="12" controlId="validationCustom01">
                                <Form.Label>ПОДРОБНОЕ ОПИСАНИЕ (до 500 букв):</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name='discr'
                                    value={values.discr}
                                    onChange={handleChange}
                                    type="text"
                                    placeholder="Опишите подробно"
                                    isInvalid={!!errors.discr}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.discr}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>

                        <Row className="mb-3">
                            <Form.Group as={Col} md="4" controlId="validationCustom04">
                                <Form.Label>КОЛИЧЕСТВО:</Form.Label>
                                <Form.Control
                                    placeholder=""
                                    name="numb"
                                    type="text"
                                    value={values.numb}
                                    onChange={handleChange}
                                    isInvalid={!!errors.numb}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.numb}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} md="4" controlId="validationCustom03">
                                <Form.Label>ЕДЕНИЦА:</Form.Label>
                                <Form.Select
                                    name='quant'
                                    value={values.quant}
                                    type="text"
                                    onChange={handleChange}
                                >
                                    {
                                        cards.quants.map(q => <option key={q.id}>{q.quant}</option>)
                                    }
                                </Form.Select>
                            </Form.Group>

                            <Form.Group as={Col} md="4" controlId="validationCustom05">
                                <Form.Label>ЦЕНА ЗА ЕДЕНИЦУ, ГРН:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name='cost'
                                    value={values.cost}
                                    onChange={handleChange}
                                    isInvalid={!!errors.cost}

                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.cost}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>

                        <Row className="mb-3">
                            <Form.Group as={Col} md="6" controlId="formFile" className="mb-3">
                                <Form.Label>Выберите фотографию объявления (по желанию)</Form.Label>
                                <Form.Control
                                    type="file"
                                    name="file"
                                    onChange={(e) => setImage(e.target.files[0])}
                                    accept="image/*"
                                />
                            </Form.Group>
                        </Row>

                        <Button type="submit" className='m-1'>СОЗДАТЬ</Button>
                        <Button className='m-1' variant="warning" onClick={() => props.setAddCard(false)}>ОТМЕНА</Button>
                    </Form>

                )}
            </Formik>
        </Container>
    );
}

export default AddNewCard;
