import { configureStore } from '@reduxjs/toolkit';
import authSlice from '../pages/AuthPage/authSlice';
import dataSlice from '../pages/dataSlice';

export const store = configureStore({   
    reducer: {
        Auth: authSlice,
        Data: dataSlice
    }
  
})