import React from 'react';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import ButtonGroup from 'react-bootstrap/ButtonGroup';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { Container } from 'react-bootstrap';


import { useSelector } from 'react-redux';



// const app = initializeApp(firebaseConfig);
// const auth = getAuth(app);

const SortPanel = (props) => { 

    const isLogin = useSelector((state) => state.Auth.user.isLogin);

    return (
        <Container >
            <Row >
                <Col sm={9} >
                <ButtonGroup className='mt-2' >
                    <DropdownButton className='me-1' variant='dark' as={ButtonGroup} title={props.leftTitle} id="bg-nested-dropdown">
                        {
                            props.leftItems.map(i => <Dropdown.Item key={i.id} onClick={() => props.setCurrentClass(i.class)} eventKey={i.id}>{i.class}</Dropdown.Item>)
                        }
                    </DropdownButton>

                    <DropdownButton className='me-1' variant='dark' as={ButtonGroup} title={props.rightTitle} id="bg-nested-dropdown">
                        {
                            props.rightItems.map(i => <Dropdown.Item key={i.id} onClick={() => props.setCurrentSort(i.sort)} eventKey={i.id}>{i.sort}</Dropdown.Item>)
                        }
                    </DropdownButton>
                    <OverlayTrigger overlay={isLogin ? <></> : <Tooltip id="tooltip-disabled">Нужно выполнить вход и подтвердить емеил...</Tooltip>}>
                        <span className="d-inline-block">
                            <Button variant='success' onClick={() => props.setAddCard(true)} disabled={isLogin ? false : true} className='rounded-0 me-1'>                                
                                +
                            </Button>
                        </span>
                    </OverlayTrigger>
                </ButtonGroup>
                </Col>
            </Row>
           


        </Container>

    );
}

export default SortPanel;
