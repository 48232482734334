import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    listOfOrders: [],
    listOfRemains: [],
    listOfContractors: []
};

const dataSlice = createSlice({
    name: 'data',
    initialState,
    reducers: {

        setListOfOrders: (state, action) => {
            state.listOfOrders = action.payload
        },

        setListOfRemains: (state, action) => {
            state.listOfRemains = action.payload
        },

        setListOfContractors: (state, action) => {
            state.listOfContractors = action.payload
        },

        clearLists: (state) => {
            state.listOfOrders = []
            state.listOfRemains = []
            state.listOfContractors = []
        },
    }
});

export const { setListOfOrders, setListOfRemains, setListOfContractors, clearLists } = dataSlice.actions;
export default dataSlice.reducer;