import { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import NavButton from '../ui/CustomNavbarButton';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from "react-router-dom";
import classes from './Styles.module.css';
import Button from 'react-bootstrap/esm/Button';
import { useNavigate } from 'react-router-dom';
import CustomNavbarButton from '../ui/CustomNavbarButton';
import Image from 'react-bootstrap/Image';

import { FcManager } from "react-icons/fc";

function Navigation(props) {
    
  const [active, setActive] = useState('orders'); 
  
  return (

    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" className='mt-1 pe-2 ps-2'>
      <Container>
        <Navbar.Brand>СТРОЙЧАТ</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Link to='/orders' className={active === 'orders' ? classes.activePage : classes.inactive} onClick={() => setActive('orders')}>ЗАКАЗЫ</Link>
            <Link to='/remains' className={active === 'remains' ? classes.activePage : classes.inactive} onClick={() => setActive('remains')}>ОСТАТКИ</Link>
            <Link to='/contractors' className={active === 'contractors' ? classes.activePage : classes.inactive} onClick={() => setActive('contractors')}>ПОДРЯДЧИКИ</Link>
          </Nav>

          {
            props.isLogin ?            
              <div style={{display: 'flex', alignItems: 'center'}}>
                <Nav>
                  {/* <FcManager /> */}
                  {
                    props.userAva !== '' ? <Image style={{width:45, height: 45}} src={props.userAva} rounded /> : null }
                  
                </Nav>
                <Nav>
                  <Link to='/auth' className={active === 'auth' ? classes.activePage : classes.inactive} onClick={() => setActive('auth')}>ПРОФИЛЬ</Link>
                </Nav>
              </div>
              : <Nav>
                <Link to='/auth' className={active === 'auth' ? classes.activePage : classes.inactive} onClick={() => setActive('auth')}>ВХОД</Link>
              </Nav>
          }
        </Navbar.Collapse>
      </Container>
    </Navbar>

  );
}

export default Navigation;