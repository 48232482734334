import React from 'react';
import { Routes, Route } from "react-router-dom";
import LoginPage from '../pages/AuthPage/LoginPage';
import RegistrationPage from '../pages/AuthPage/RegistrationPage';
import AuthPage from '../pages/AuthPage/AuthPage';
import OrdersPage from '../pages/OrdersPage';
import RemainsPage from '../pages/RemainsPage';
import ContractorsPage from '../pages/ContractorsPage';


export default function AppRouter(props) {

  return (
    // <div style={{ height: '80%' }}>
      <Routes>
        {/* <Route path="/" element={<Main mass_img={props.mass_img} />} /> */}
        <Route path="/orders" element={<OrdersPage isLogin={props.isLogin}></OrdersPage>} />
        <Route path="/remains" element={<RemainsPage isLogin={props.isLogin}></RemainsPage>} />
        <Route path="/contractors" element={<ContractorsPage></ContractorsPage>} />
        <Route path="/auth" element={<AuthPage isLogin={props.isLogin} setIsLogin={props.setIsLogin}></AuthPage>} />
        <Route path="/login" element={<LoginPage isLogin={props.isLogin} setIsLogin={props.setIsLogin}></LoginPage>} />
        <Route path="/registration" element={<RegistrationPage isLogin={props.isLogin} setIsLogin={props.setIsLogin}></RegistrationPage>} />
        <Route path="*" element={<OrdersPage></OrdersPage>} />
      </Routes>
    // </div>

  );
}
