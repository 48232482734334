import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Stack from 'react-bootstrap/Stack';


import { useState } from 'react';



import { HiOutlineBanknotes } from "react-icons/hi2";
import { FcCalendar, FcCalculator, FcHome, FcRating, FcGallery, FcMms } from "react-icons/fc";
import { Container } from 'react-bootstrap';

import classes from './Styles.module.css';
import CustomModal from '../ui/CustomModal';


const CustomCard = (props) => {


  const [fullscreen, setFullscreen] = useState(false);
  const [show, setShow] = useState(false);

  function handleShow() {
    setFullscreen('lg-down');
    setShow(true);
  };

  const drawStars = () => {
    if (props.typeCard === 'contractors') {
      const mass = [];
      for (let i = 0; i < props.card.stars; i++) {
        mass.push(<FcRating key={Math.random().toString(36).substring(7)} />);
      }
      return mass;
    }
  };

  const getDateForCard = (date) => {
    let tmpDate = new Date(date);
    let day = tmpDate.getDate() < 10 ? '0' + tmpDate.getDate() : tmpDate.getDate();
    let month = tmpDate.getMonth() < 10 ? '0' + (tmpDate.getMonth() + 1) : tmpDate.getMonth();
    let year = tmpDate.getFullYear();

    return day + '.' + month + '.' + year;
  };

  return (
    
    <Container className='p-0 mt-2'>
      <Card className={['mt-2', 'mb-2', classes.card]} onClick={handleShow}>
        <Card.Header style={{ display: 'flex', backgroundColor: '#4d638c', borderRadius: 0 }} >
          <div style={{ color: '#cfe0f9', fontSize: 22 }}>
            {props.card.class}
            {
              props.card.img !== 'none' ? <FcGallery style={{marginLeft: 10}}/> : null
            }
            
          </div>
          <div className="ms-auto" style={{ color: '#cfe0f9', fontSize: 18 }}>
            <div><FcHome /> &ensp;{props.card.location}</div>
          </div>

        </Card.Header>
        
        <Card.Body>
          <Stack  direction="horizontal">
          {/* <Card.Img variant="top" style={{width: 100}} src='https://parsefiles.back4app.com/HgZ11efHpJ67l9V9fjbPLnFECFBds9soS9NN8gu1/35fd4ed705a487052b620fa5c8a6f6b7_ava_r.JPG'/> */}
          <Stack>
            <Card.Title style={{ fontSize: 20, }}>{props.card.title} </Card.Title>
            <Card.Text>
              {props.card.discription.slice(0, 100) + '...   '}
            </Card.Text>
          </Stack>
          
          <Stack>
            <div className="ms-auto">

              {props.typeCard === 'orders' ?
                <div>
                  <div><FcCalendar /> &ensp;{getDateForCard(props.card.date)}</div>
                  <div><FcCalculator /> &ensp;{props.card.sumcost} грн. </div>
                </div>
                :
                null
              }
              {props.typeCard === 'remains' ?
                <div>
                  <div><FcCalendar /> &ensp;{getDateForCard(props.card.date)}</div>
                  <div><FcCalculator /> &ensp;{props.card.sumcost} грн. </div>
                </div>
                :
                null
              }
              {props.typeCard === 'contractors' ?
                <div>
                  <div><FcCalendar /> &ensp;{getDateForCard(props.card.date)}</div>
                  <div>
                    {
                      drawStars()
                    } &ensp; </div>
                </div>
                :
                null
              }
            </div>
          </Stack>

          </Stack>
          

        </Card.Body>
      </Card>


      <CustomModal show={show} typeCard={props.typeCard} fullscreen={fullscreen} setShow={setShow} card={props.card}></CustomModal>
      
    </Container>

  );
}

export default CustomCard;
