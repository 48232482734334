import React, { useState } from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import { Formik } from 'formik';
import * as yup from 'yup';
import Parse from 'parse';
import cards from '../../app/cards';
import { useNavigate } from 'react-router-dom';

import { imageIn64 } from '../../app/image';
import { useDispatch } from 'react-redux';
import { setUser } from './authSlice';
import { ButtonGroup } from 'react-bootstrap';

import * as HandleData from './../../app/functions';
import imageCompression from 'browser-image-compression';

import Card from 'react-bootstrap/Card';


Parse.initialize("HgZ11efHpJ67l9V9fjbPLnFECFBds9soS9NN8gu1", "E5KCSFDLfY3WDB5FyB6bIRIoWrRBJbfDJ6zO3gSy"); //PASTE HERE YOUR Back4App APPLICATION ID AND YOUR JavaScript KEY
Parse.serverURL = 'https://parseapi.back4app.com/';


const schema = yup.object().shape({
    email: yup.string().email('Ошибка в адресе').required('Обязательное поле'),
    password: yup.string().min(6, 'Слишком короткий! (минимум 6)').max(10, 'Слишком длинный! (максимум 10)').required('Обязательное поле'),
    username: yup.string().min(4, 'Слишком короткое! (минимум 4)').max(16, 'Слишком длинное! (максимум 16)').required('Обязательное поле'),
    city: yup.string().min(4, 'Слишком короткий! (минимум 4)').max(20, 'Слишком длинный! (максимум 20)').required('Обязательное поле'),
    phone: yup.string().min(13, '050 111 11 11').max(13, '050 111 11 11').required('Обязательное поле'),

});


const RegistrationPage = (props) => {

    const navigate = useNavigate();

    const [image, setImage] = useState('none');
    // const [smallImage, setSmallImage] = useState('');

    const dispatch = useDispatch();    

    const doUserRegistration = async function (data) {
        // Note that these values come from state variables that we've declared before
        const usernameValue = data.username;
        const passwordValue = data.password;
        const emailValue = data.email;
        const city = data.city;
        const phone = data.phone;
        // const userImage = data.file   

        try {
            let avaDownLoaded = { url: 'none', id: 'none' };

            var user = new Parse.User();
            user.set("username", usernameValue);
            user.set("password", passwordValue);
            user.set("email", emailValue);
            user.set("phone", phone);
            user.set("city", city);
            user.set("ava", avaDownLoaded.url);


            if (image !== 'none') {
                console.log('Before ', image)
                let compressedImage = await HandleData.compressImage(image);
                console.log('After ', compressedImage);
                avaDownLoaded = await HandleData.uploadImage('AvaGallery', compressedImage);
                // avaDownLoaded = await downloadImage('AvaGallery', userId); 
                user.set("ava", avaDownLoaded.url);
                user.set("imgId", avaDownLoaded.id);
            };

            const createdUser = await user.signUp();

            const userId = Parse.User.current().id;

            // await Parse.User.requestEmailVerification(emailValue); // EMAIL VERIFICATION !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

            // let tmpUser = {
            //     email: emailValue,
            //     userId: userId,
            //     name: usernameValue,
            //     photoURL: '',
            //     isLogin: true,
            //     role: '',
            //     displayName: '',
            //     phone: phone,
            //     ava: avaDownLoaded.url,
            //     city: city,
            //     // emailVerified: false
            // };

            // dispatch(setUser(
            //     tmpUser
            // ));

            alert(
                `Успешная регистрация! Пользователь ${createdUser.getUsername()} был успешно создан! 
                Для получения доступа к функциям сайта подтвердите получение письма на почте, указанной при регистрации и войдите на сайт.`
            );

            await Parse.User.logOut();

            navigate('/auth');
            return true;

        } catch (error) {
            // signUp can fail if any parameter is blank or failed an uniqueness check on the server
            alert(`Ошибка регистрации ! ${error}`);
            navigate('/auth');
            return false;
        };
    };

    // const compressImage = async (img) => {
    //     const imageFile = img;
    //     const options = {
    //       maxSizeMB: 0.5,
    //       maxWidthOrHeight: 200
    //     }
    //     try {
    //       const compressedFile = await imageCompression(imageFile, options);
    //       return (compressedFile);
    //     } catch (error) {
    //       console.log('Ошибка сжатия файла ', error);
    //     }      
    //   };


    // const downloadImage = async (nameGallery, userId) => {
    //     try {
    //         let query = new Parse.Query(nameGallery);
    //         query.contains('userId', userId);
    //         let queryResult = await query.find();
    //         return queryResult[0].get('picture').url();
    //     } catch (error) {
    //         console.log('Ошибка загрузки аватарки ', error);
    //     }

    // };

    // const compressImage = async (img) => { // Получение, сжатие и возврат сжатого файла для отправки в базу...
    //     const imageFile = img;
    //     const options = {
    //         maxSizeMB: 0.2,
    //         maxWidthOrHeight: 500
    //     }
    //     try {
    //         const compressedFile = await imageCompression(imageFile, options);
    //         console.log('After compressing: ', compressedFile)
    //         let url = URL.createObjectURL(compressedFile);
    //         console.log(url)
    //         setSmallImage(url);
    //     } catch (error) {
    //         console.log('Ошибка сжатия файла! ', error);
    //     }
    // };


    return (
        <div>
            <h2>РЕГИСТРАЦИЯ НОВОГО ПОЛЬЗОВАТЕЛЯ</h2>
            <p>После регистрации проверьте указанную почту и пройдите по ссылке в письме для подтверждения регистрации.</p>
            <Formik
                validationSchema={schema}
                onSubmit={values => doUserRegistration(values)}
                initialValues={{
                    userId: '',
                    email: '',
                    password: '',
                    username: '',
                    city: cards.locations[0].location,
                    phone: '',
                    file: ''

                }}
            >
                {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    isValid,
                    errors,
                }) => (
                    <Form noValidate onSubmit={handleSubmit}>

                        <Row className="mb-3">
                            <Form.Group as={Col} md="6" controlId="validationFormik02">
                                <Form.Label>Логин (для входа на сайт, английскими):</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="username"
                                    value={values.username}
                                    onChange={handleChange}
                                    isValid={touched.username && !errors.username}
                                    isInvalid={!!errors.username}
                                />
                                <Form.Control.Feedback type="invalid">{errors.username}</Form.Control.Feedback>
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            </Form.Group>
                        </Row>

                        <Row className="mb-3">
                            <Form.Group as={Col} md="6" controlId="validationFormik01">
                                <Form.Label>Почта:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="email"
                                    value={values.email}
                                    onChange={handleChange}
                                    isValid={touched.email && !errors.email}
                                    isInvalid={!!errors.email}
                                />
                                <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            </Form.Group>
                        </Row>

                        <Row className="mb-3">
                            <Form.Group as={Col} md="6" controlId="validationFormik02">
                                <Form.Label>Пароль (от 6 до 10 символов):</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="password"
                                    value={values.password}
                                    onChange={handleChange}
                                    isValid={touched.password && !errors.password}
                                    isInvalid={!!errors.password}
                                />
                                <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            </Form.Group>
                        </Row>

                        <Row className="mb-3">
                            <Form.Group as={Col} md="6" controlId="validationFormik02">
                                <Form.Label>Номер телефона (формат 050 555 55 55):</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="phone"
                                    value={values.phone}
                                    onChange={handleChange}
                                    isValid={touched.phone && !errors.phone}
                                    isInvalid={!!errors.phone}
                                />
                                <Form.Control.Feedback type="invalid">{errors.phone}</Form.Control.Feedback>
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            </Form.Group>
                        </Row>

                        <Row className="mb-3">
                            <Form.Group as={Col} md="6" controlId="validationCustom03">
                                <Form.Label>Город:</Form.Label>
                                <Form.Select
                                    name='city'
                                    value={values.city}
                                    onChange={handleChange}
                                    isInvalid={!!errors.city}
                                >
                                    {
                                        cards.locations.map(l => <option key={l.id}>{l.location}</option>)
                                    }
                                </Form.Select>

                                <Form.Control.Feedback type="invalid">
                                    {errors.city}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>

                        <Row className="mb-3">
                            <Form.Group as={Col} md="6" controlId="formFile" className="mb-3">
                                <Form.Label>Выберите фотографию для аватара (по желанию)</Form.Label>
                                <Form.Control
                                    type="file"
                                    name="file"
                                    onChange={(e) => setImage(e.target.files[0])}
                                    accept="image/*"
                                />
                            </Form.Group>
                        </Row>
                        <ButtonGroup>
                            <Button className='me-1 mt-2' variant="success" type="submit">РЕГИСТРАЦИЯ</Button>
                            <Button className="me-1 mt-2" variant="danger" onClick={() => navigate('/orders')}>
                                ОТМЕНА
                            </Button>
                        </ButtonGroup>
                    </Form>

                )}
            </Formik>
            {/* <Form.Group as={Col} md="6" controlId="formFile" className="mb-3">
                <Form.Label>Выберите фотографию для аватара (по желанию)</Form.Label>
                <Form.Control
                    type="file"
                    name="image1"
                    onChange={(e) =>console.log(e.target.files[0])}
                    accept="image/*"
                />
            </Form.Group>
            <Button onClick={uploadImage('Gallery')}>FILE UPLOAD</Button>
            <Button onClick={downloadImage('Gallery')}>FILE DOWNLOAD</Button> */}
            {/* <Card className='mt-2' style={{ width: '30%', }}>
                {
                    smallImage !== '' ? <Card.Img variant="top" src={smallImage} />
                        :
                        null
                }

                <Card.Body>
                    <Card.Title>{smallImage.name}</Card.Title>
                    <Card.Text>{smallImage.size}</Card.Text>
                   
                </Card.Body>
            </Card>
            <Button onClick={()=>compressImage(image)}>COMPRESS FILE</Button> */}
        </div>
    );
}

export default RegistrationPage;
