import React, {  useState } from 'react';

// import Container from 'react-bootstrap/Container';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
import CustomPagination from '../ui/CustomPagination';

import CustomCard from '../components/CustomCard';

import cards from '../app/cards';
import { Container } from 'react-bootstrap';

const NUMBITEMS = 6;

const ContractorsPage = () => {

    const[itemsForList, setItemsForList] = useState(cards.cardsContractors.slice(0,6));

    const currentPageList = ()=>{
        let countPages = parseInt(cards.cardsContractors.length / NUMBITEMS);
        return countPages + 1;
    };

    const getItemsforList = (numberPage)=>{
        const contentPerPage = NUMBITEMS;
        const lastIndex = numberPage * contentPerPage;
        const firstIndex = lastIndex - contentPerPage;
        setItemsForList(cards.cardsContractors.slice(firstIndex, lastIndex));               
    };

    return (
        <Container className='p-0'>
            {
                itemsForList.map((i)=><CustomCard key={i.id} card={i} typeCard ='contractors'></CustomCard>) 
            }
            <CustomPagination getItemsforList = {getItemsforList} pages={currentPageList()}></CustomPagination> 
        </Container>
    );
}

export default ContractorsPage;
